
var selectedFilters = [];
var availableDrugs = [];
var currentSelector = '';
var datalayerService;
var printingService;
var drugAdminSelected = new Map([
    ["searchTextareaDrugFoodAdmin", []],
    ["searchTextareaGrapefruit", []]
]);

// note: library is autoComplete.js by tarekraafat
function initializeAutocomplete(selector) {
    const autoCompleteJS = new autoComplete({
        selector: "#" + selector,
        data: {
            src: availableDrugs,
            cache: true,
            filter: (list) => {
                // the return result is what's displayed
                // e.g. list.filter() will filter results
                return list
            }
        },
        searchEngine: 'loose',
        resultsList: {
            element: (list, data) => {
                if (!data.results.length) {
                    // Create "No Results" message element
                    const message = document.createElement("div");
                    // Add class to the created element
                    message.setAttribute("class", "no_result");
                    // Add message text content
                    message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
                    // Append message element to the results list
                    list.prepend(message);
                }
            },
            noResults: true,
            class: 'autocomplete-result-list',
            maxResults: 9999
        },
        resultItem: {
            highlight: false,
            element: (item, data) => {
                if (drugAdminSelected.get(currentSelector).indexOf(data.value) > -1) {
                    item.classList.add('unavailable-filter');
                }
            }
        },
        events: {
            input: {
                selection: (event) => {
                    const selection = event.detail.selection.value;
                    if (drugAdminSelected.get(currentSelector).indexOf(selection) === -1) {
                        autoCompleteJS.input.value = selection;
                        drugAdminSelected.get(currentSelector).push(selection);
                        onFilterSelected(selection, currentSelector);
                    }
                },
                focus: (event) => {
                    if (autoCompleteJS.input.value === '') {
                        autoCompleteJS.start(' ');
                    }
                }
            }
        }
    });
}

function toggleSidebar() {
    var currentDoc = document.querySelector('.active-document');
    var sideBarEl = currentDoc.querySelector('.drug_admin_and_food');
    var toggleArrowEl = currentDoc.querySelector('.show-sidebar');

    sideBarEl.classList.toggle('hidden');
    sideBarEl.classList.toggle('zero-width');
    toggleArrowEl.classList.toggle('hidden');
}

function hideShowText(type) {
    var currentDoc = document.querySelector('.active-document');
    more = currentDoc.querySelector("#moreButton" + capitalizeFirstLetter(type));
    var hiddenTextClassName = type === 'food' ? 'hiddenText' : 'hiddenGrapeText';
    hiddenTextEls = document.getElementsByClassName(hiddenTextClassName);

    for (i = 0; i < hiddenTextEls.length; i++) {

        var e = hiddenTextEls[i];

        if (e.style.display === "none") {
            e.style.display = "block";
        } else {
            e.style.display = "none";
        }
    }

    more.classList.toggle('hidden');
}

function initializeSelector(){
    currentSelector = '';
}

function initializeFilterList(selector) {
    //if (initializedTableSelector.indexOf(selector) < 0) {
    if (currentSelector !== selector) {
        var currentDoc = document.querySelector('.active-document');
        var currentTable = currentDoc.querySelector('.dita-tbody');
        var drugsRowList = currentTable.querySelectorAll('tr.dita-row');
        availableDrugs = [];

        for (i = 0; i < drugsRowList.length; i++) {
            var currentDrug = drugsRowList[i].firstElementChild.firstElementChild;
            if (!!currentDrug) {
                var currentDrugTextValue = currentDrug.textContent || currentDrug.innerText;
               // currentDrugTextValue = currentDrugTextValue.replace(/(\r\n|\n|\r)/gm, "").trim();
                currentDrugTextValue = currentDrugTextValue.replace((/  |\r\n|\n|\r/gm), "");
                availableDrugs.push(currentDrugTextValue);
            }
        }
        initializeAutocomplete(selector);
     //   initializedTableSelector.push(selector);
        currentSelector = selector;
        searchTextareaFocus();
    }
}

function initiatePreservedFilteredList(activeDocumentFlag) {
    for (var i = 0; i < drugAdminSelected.get(activeDocumentFlag).length; i++) {
        onFilterSelected(drugAdminSelected.get(activeDocumentFlag)[i], activeDocumentFlag);
    }
}

function onFilterSelected(filterText, activeDocument) {
    var currentDoc = document.querySelector('.active-document');
    const filterDiv = currentDoc.querySelector('#filteredListIdentifier');
    filterDiv.classList.remove('hidden');
    const searchTextArea = currentDoc.querySelector('.searchTextarea');
    var newFilterSpan = document.createElement('span');
    newFilterSpan.classList.add('filter-tag');
    newFilterSpan.innerText = filterText;
    newFilterSpan.addEventListener('click', function() {
        for (var i = 0; i < drugAdminSelected.get(activeDocument).length; i++) {
            if (drugAdminSelected.get(activeDocument)[i] === filterText) {
                drugAdminSelected.get(activeDocument).splice(i, 1);
            }
        }
        datalayerService.clininfoToolsFilterChangedEvent(filterText, false);
        filterList(currentDoc, activeDocument);
        this.remove();
    });

    searchTextArea.parentNode.insertBefore(newFilterSpan, searchTextArea);
    searchTextArea.value = '';

    datalayerService.clininfoToolsFilterChangedEvent(filterText, true);

    filterList(currentDoc, activeDocument);
}

function filterList(currentDoc, activeDocument) {
    var currentTable = currentDoc.querySelector('.dita-tbody');
    var drugsRowList = currentTable.querySelectorAll('tr.dita-row');
    var selectedItems = drugAdminSelected.get(activeDocument);
    if (selectedItems.length > 0) {
        for (i = 0; i < drugsRowList.length; i++) {
            var currentDrug = drugsRowList[i].firstElementChild.firstElementChild;
            if (!!currentDrug) {
                var textValue = currentDrug.textContent || currentDrug.innerText;
                textValue = textValue.replace((/  |\r\n|\n|\r/gm), "");
                if (selectedItems.indexOf(textValue) === -1) {
                    if (!drugsRowList[i].classList.contains('hidden'))
                        drugsRowList[i].classList.add('hidden');
                } else {
                    drugsRowList[i].classList.remove('hidden');
                }

            }
        }

        var currentlyVisibleReferences = [];
        var referenceNumberMapping = {};

        currentTable.querySelectorAll('.dita-row').forEach(drugElement => {
            if (!drugElement.classList.contains('hidden')) {
                drugElement.querySelectorAll('.ref').forEach(refLink => {
                    var referenceId = refLink.getAttribute('href').replace('#', '');
                    if (!!referenceId && currentlyVisibleReferences.indexOf(referenceId) < 0) {
                        currentlyVisibleReferences.push(referenceId);
                        referenceNumberMapping[referenceId] = refLink.innerText;
                    }
                });
            }
        });

        // This is hidding the references after a search which I don't believe we now want. Just put this back if we desire this functionality. 
        // currentDoc.querySelectorAll('.refitem').forEach(reference => {
        //     var referenceId = reference.getAttribute('id');
        //     if (!!referenceId && currentlyVisibleReferences.indexOf(referenceId) < 0) {
        //         reference.classList.add('hidden');
        //     } else {
        //         reference.classList.remove('hidden');
        //         reference.value = referenceNumberMapping[referenceId].replace('[','').replace(']', '');
        //     }
        // });

    } else {
        resetFilters(activeDocument);
    }
}

function resetFilters(activeDocument) {
    // var length = drugAdminSelected.get(activeDocument).length;
    // drugAdminSelected.get(currentSelector).splice(0, length);
    drugAdminSelected.set(currentSelector, []);
    //availableDrugs = [...initialAvailableDrugs];
    var currentDoc = document.querySelector('.active-document');
    var currentTable = currentDoc.querySelector('.dita-tbody');
    var drugsRowList = currentDoc.querySelectorAll('tr.dita-row');

    var filterTags = currentDoc.querySelectorAll('.filter-tag');
    for (var i = 0; i < filterTags.length; i++) {
        filterTags[i].remove();
    }

    for (var i = 0; i < drugsRowList.length; i++) {
        drugsRowList[i].classList.remove('hidden');
    }

    const filterDiv = currentDoc.querySelector('#filteredListIdentifier');
    if (!filterDiv.classList.contains('hidden'))
        filterDiv.classList.add('hidden');

    currentDoc.querySelectorAll('.refitem').forEach(reference => {
        reference.classList.remove('hidden');
    })
}

function searchTextareaFocus() {
    var currentDoc = document.querySelector('.active-document');
    const searchTextArea = currentDoc.querySelector('.searchTextarea');
    searchTextArea.focus();
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function printFilteredContent(docType, lang, docId, contentID) {
    var mainDivElement = document.createElement("div");
    mainDivElement.classList.add("chapter-wrap");
    documentPanel = null;
    if (docId === 'drugadminandfood'){
        documentPanel = document.getElementById('drug_admin_and_food');
    }else{
        documentPanel = document.getElementById(docId);
    }
    //Preparing Header
    if (documentPanel !== null && documentPanel !== undefined) {
        var headerDiv = document.createElement("div");
        var chapterTitle = documentPanel.querySelector(".chapter-head").cloneNode(true);
        var chapterInfo = documentPanel.querySelector(".chapter-info").cloneNode(true);
        headerDiv.appendChild(chapterTitle);
        headerDiv.appendChild(chapterInfo);
        mainDivElement.appendChild(headerDiv);
    }
    //Preparing Selected Content
    selectedContent = document.querySelector('#' + contentID).cloneNode(true);
    var contentDivElement = document.createElement("div");
    contentDivElement.classList.add("panel-body-cls");
    contentDivElement.classList.add("orphan-section");
    contentDivElement.appendChild(selectedContent);
    mainDivElement.appendChild(contentDivElement);

    //Preparing References
    let referencesIDsArray = [];
    selectedContent.querySelectorAll('.dita-row').forEach(drugElement => {
        if (!drugElement.classList.contains('hidden')){
            drugElement.querySelectorAll('.ref').forEach(refLink => {
                var refIndex = refLink.firstChild.innerText.replace('[', '').replace(']', '');
                referencesIDsArray.push(refIndex + ',' + refLink.hash.replace('#', ''));
            });
        }
    });
    if (referencesIDsArray.length > 0) {
        var referencesPanel = document.createElement("div");
        var referenceHeader = document.getElementById('references').cloneNode(true).firstElementChild;
        referencesPanel.appendChild(referenceHeader);
        ulTag = document.createElement("ol");

        //Sorting References
        referencesIDsArray.sort(function (a, b) { return a.split(',')[0] - b.split(',')[0] });
        //Removing duplicates
        let lastReference = null;
        referencesIDsArray.forEach(reference => {
            reference = reference.split(',');
            if (reference[0] !== lastReference) {
                var elmnt = document.getElementById(reference[1]).cloneNode(true);
                elmnt.setAttribute("value", reference[0]);
                ulTag.appendChild(elmnt);
                lastReference = reference[0];
            }
        })
        referencesPanel.appendChild(ulTag);
        mainDivElement.appendChild(referencesPanel);
    }

    const modalData = {
        doc: documentService.getDocument(docType, docId, lang),
        htmlElement: mainDivElement
    };

    printingService.printContent(modalData, contentID);
}